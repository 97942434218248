import { render, staticRenderFns } from "./ProductCategoryEdit.vue?vue&type=template&id=3d0e7c90&scoped=true&"
import script from "./ProductCategoryEdit.vue?vue&type=script&lang=js&"
export * from "./ProductCategoryEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0e7c90",
  null
  
)

export default component.exports